<template>
    <div class="wrap">
        <div class="bar" @click="$router.push('/')">
            <img 
                class="illustrationBird"
                :src="require('@/assets/illustrations/bird.svg')" 
                alt=""
            >
            <h3 class="nameTitle">Tahkuranna Maastikukaitse</h3>
        </div>
        <div class="logo">
            <img 
                class="logoImg"
                @click="$router.push('/')"
                :src="require('@/assets/logo/mainLogo.svg')" 
                alt=""
            >
            <h2>MTÜ Tahkuranna Maastikukaitse</h2>
        </div>
        <div class="aboutText">
            <p>Tahkuranna Maastikukaitse on ellu kutsutud, et seista hea meie piirkonna keskkonnaväärtuste säilimise ning heaolu eest.<br>
            <br>
            MTÜ on loodud vastulöögina algatusele avada Võiste II liivakarjäär, mille puhul plaanitakse liiva kaevandamist <b>Uulu-Võiste maastikukaitseala</b> ja <b>Jõulumäe terviseradade</b> vahetus läheduses.
            <br>
            <br>
            <b id="project">310</b> kohalikku elanikku on andnud oma allkirja, näidates üles vastuseisu eraisiku algatusele muuta <b>7ha</b> suurune <b>männimets</b> liivakarjääriks.</p>
        </div>

        <div class="stats">
            <div class="singleStat">
                <img 
                    :src="require('@/assets/icons/activity.svg')" 
                    alt=""
                >
                <p><b>7 kuud</b> MTÜ tegevust</p>
            </div>
            <div class="singleStat">
                <img 
                    :src="require('@/assets/icons/members.svg')" 
                    alt=""
                >
                <p><b>10</b> aktiivset <b>liiget</b></p>
            </div>
            <div class="singleStat">
                <img 
                    :src="require('@/assets/icons/court.svg')" 
                    alt=""
                >
                <p><b>2 kohtuvaiet</b> - Võiste II karjääri metsateatis ja kaeveluba</p>
            </div>
            <div class="singleStat">
                <img 
                    :src="require('@/assets/icons/signature.svg')" 
                    alt=""
                >
                <p><b>310 allkirja</b> karjääri vastu</p>
            </div>
            <div class="singleStat">
                <img 
                    :src="require('@/assets/icons/crowd.svg')" 
                    alt=""
                >
                <p><b>87 osavõtjat</b> avalikul arutelul</p>
            </div>
        </div>

        <div class="contact">
            <div class="single">
                <h3>MTÜ Tahkuranna Maastikukaitse</h3>
                <p>Pärnu maakond, Häädemeeste vald, Leina küla, Kaasiku, 86504</p>
                <p>Reg. 80614207</p>
                <p>EE847700771008199323 (LHV)</p>
                <p class="email">tahkurannamaastikukaitse@gmail.com</p>
            </div>

            <div class="single">
                <h3>Juhatuse liige</h3>
                <p style="font-size: 1.3rem">Teet Suursild</p>
                <p>+372 5386 7085</p>
                <p class="email">teetsuur@gmail.com</p>
            </div>
        </div>    
        <darkSection class="darkSection" />      
    </div>
</template>

<script>
import darkSection from '@/components/home/darkSection.vue'

export default {
    name: 'contactPage',
    components: {
        darkSection
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Meist',
        })
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');

.wrap {
    position: relative;
    background: white;
    padding-top: 100px;
    padding-bottom: 100px;
}
.bar {
    height: 57px;
    width: 100%;
    background: rgb(58, 82, 85);
    position: absolute;
    top: 0;
    cursor: pointer;
}
.illustrationBird {
    position: absolute;
    height: 40px;
    top: 9px;
    left: 15px;
}
.nameTitle {
    color: #BEC4CD;
    position: absolute;
    top: 21px;
    left: 60px;
    bottom: 0;
    margin: auto;
    font-size: 1.1rem;
    font-family: 'Quicksand', sans-serif;
}
.nameTitle:hover {
    color: #dce3ee;
    cursor: default;
}
.logo {
    text-align: center;
}
.logoImg {
    width: 300px;
    margin-top: 35px;
    margin-bottom: 10px;
}
.aboutText p {
    width: 1000px;
    margin: 50px auto;
    margin-bottom: 20px;
    color: rgb(90, 90, 90);
    font-size: 1.1rem;
    font-family: 'Quicksand', sans-serif;

}
h2 {
    text-align: center;
    color: rgb(60, 60, 60);
    text-align: center;
    font-size: 3rem;
    margin: 0;
    margin-top: 25px;
    margin-bottom: 30px;
    font-family: 'Bree Serif', serif;
}
.contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 1000px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 100px;
}
.single {
    width: 48%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: white;
    color: rgb(90, 90, 90);
}
.single:first-child {
    width: 52%;
}
.single:last-child {
    width: 42%;
}
.single p {
    width: 80%;
    margin: 10px auto;
    text-align: start;
    font-size: 1.15rem;
    font-family: 'DM sans', sans-serif;
}
.single h3 {
    color: rgb(65, 65, 65);
    font-size: 1.7rem;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
    font-family: 'DM sans', sans-serif;
}
.email {
    cursor: pointer;
    text-decoration: underline;
    color: steelblue;
}
.darkSection {
    margin-bottom: 75px;
}
.stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 1000px;
    margin: 40px auto;
    margin-bottom: 50px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.singleStat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 49%;
    margin-bottom: 20px;
    border: 1px solid rgb(230,230,230);
    padding: 12px 10px 10px 15px;
    border-radius: 100px;
    background: rgb(255,255,255);
}
.singleStat:hover {
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    cursor: default;
    background: rgb(250,250,250);
}
.singleStat:nth-child(3) {
    width: 100%;
}
.singleStat p {
    margin: 0;
    color: rgb(50,50,50);
    font-family: 'DM sans', sans-serif;
    font-size: 18px;
}
.singleStat b {
    color: rgb(25,25,25);
}
.singleStat img {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-right: 13px;
    margin-left: 5px;
    margin-bottom: auto;
}
@media (max-width: 1010px) {
    .contact {
        margin-top: -25px;
        width: 100%;
    }
    .stats {
        width: 95%;
    }
    .aboutText p {
        width: 95%;
    }
    h2 {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (max-width: 992px) {
    .wrap {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
@media (max-width: 660px) {
   .single:first-child {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .single:last-child {
        width: 90%;
        margin: 0 auto;
    }
    h2 {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 2.7rem;
        margin-bottom: 0px;
    }
    .single {
        margin-bottom: 0px;
    }
}
@media (max-width: 550px) {
    .singleStat {
        width: 100%;
    }
}
@media (max-width: 450px) {
    .darkSection {
        margin-top: -50px;
        margin-bottom: 25px;
    }
    .wrap {
        padding-top: 55px;
        padding-bottom: 50px;
    }
    h2 {
        margin-bottom: 10px;
    }
    .email {
        word-wrap: break-word;
    }
}
</style>
